<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12">Acreditadores</h1>
    </div>

    <DataGrid :configuracion="datagrid_config" :data="acreditadores" @ready="buscar_btns">
      <button class="btn btn-secondary mr-2" @click="obtener_acreditadores">Recargar</button>
      <button class="btn btn-primary" @click="show_modal=true">Nuevo</button>
    </DataGrid>

    <Formulario v-if="show_modal" :acreditador="acreditador" :acreditadores="acreditadores" @close="cerrar_modal" @update="cerrar_modal(true)" />

    <Modal v-if="show_modal_delete" :options="{width: '30vw',type:'danger',esc:false}">
      <div class="title">Eliminar acreditador</div>
      <div class="body">
        <p class="text-center">¿Realmente deseas eliminar el acreditador?</p>
        <p class="text-center">
          <button class="btn btn-primary mr-2" @click="eliminar_acreditador">Eliminar</button>
          <button class="btn btn-danger" @click="cerrar_modal">Cancelar</button>
        </p>
      </div>
    </Modal>
  </div>
</template>

<script>
import DataGrid from '@/components/DataGridV3';
import Modal from '@/components/Modal';

import Formulario from './Acreditador/Modal';

import api from '@/apps/analyticpoint/api/comite/acreditadores';

export default {
  components: {
    DataGrid, Formulario, Modal
  },
  data() {
    return {
      datagrid_config: {
        cols: {
          nombre: 'Nombre',
          tipo: 'Tipo',
          obligatorio: 'Obligatorio',
          estatus: 'Estatus',
          otros: 'Otros'
        },
        mutators:{
          obligatorio(value) {
            return value ? 'Sí' : 'No';
          },
          otros(val, row, vue) {
            return '<button class="btn btn-secondary mr-2 btn-accion" data-acreditador="'+vue.$helper.toJson(row)+'" data-accion="editar"><i class="fa-regular fa-pen-to-square"></i></button>'
                    + '<button class="btn btn-secondary btn-accion" data-acreditador="'+vue.$helper.toJson(row)+'" data-accion="eliminar"><i class="fa-solid fa-trash-can"></i></button>';
          }
        },
        selector: false,
        search: ['nombre','tipo','obligatorio','estatus']
      },
      acreditadores: [],
      show_modal: false,
      acreditador: null,
      show_modal_delete: false
    }
  }
  ,mounted() {
    this.obtener_acreditadores();
  },
  methods: {
    async obtener_acreditadores() {
      try {
        this.acreditadores = (await api.obtener_acreditadores()).data;
      }catch(e) {
        this.$log.error('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    buscar_btns() {
      document.querySelectorAll('button.btn-accion').forEach(btn => {
        btn.removeEventListener('click', this.abrir_modal);
        btn.addEventListener('click', this.abrir_modal);
      });
    },
    abrir_modal(e) {
      let btn = e.target;

      if (btn.tagName == 'I')
        btn = btn.parentElement;

      this.acreditador = this.$helper.fromJson(btn.dataset.acreditador);
      
      if (btn.dataset.accion == 'editar') this.show_modal = true; else this.show_modal_delete = true;
    },
    cerrar_modal(actualizar) {
      if (typeof actualizar == 'undefined')
        actualizar = false;

      this.show_modal = false;
      this.show_modal_delete = false;
      this.acreditador = null;

      if (actualizar)
        this.obtener_acreditadores();
    },
    async eliminar_acreditador() {
      try {
        let res = (await api.eliminar_acreditador(this.acreditador.id));
        this.$log.info('res', res);
        this.cerrar_modal(true);
      }catch(e) {
        this.$log.error(e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  }
}
</script>